import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

type ContainerProps = {
  $isLoggedIn?: boolean;
};

export const Container = styled.div<ContainerProps>`
  &,
  & .sa-avatar-widget__image {
    inline-size: 32px;
    block-size: 32px;

    ${theme.breakpoints.up("tablet")} {
      inline-size: 42px;
      block-size: 42px;
    }
  }

  ${props =>
    props.$isLoggedIn
      ? css`
          & .sa-avatar-widget__image {
            inline-size: 28px;
            block-size: 28px;
            margin: 2px;
            border: 1px #ffffff solid;
          }
        `
      : null}

  ${theme.breakpoints.up("tablet")} {
    ${props =>
      props.$isLoggedIn
        ? css`
            & {
              inline-size: 40px;
              block-size: 40px;
            }
            & .sa-avatar-widget__image {
              inline-size: 36px;
              block-size: 36px;
            }
          `
        : null}
  }

  & div,
  & .sa-avatar-widget__image {
    padding: 0;
  }
`;
