import styled from "@emotion/styled";
import { theme, transientOptions } from "@product/scmp-sdk";

import { Avatar } from "scmp-app/components/avatar";
import { OffsetContainer } from "scmp-app/components/posties/offset-container";

import IconAvatar from "./icon-avatar.svg";

type ContainerProps = {
  $isLoggedIn?: boolean;
};

export const StyledAvatar = styled(Avatar, { ...transientOptions })<ContainerProps>`
  position: relative;
`;

export const StyledAnonymousAvatar = styled(IconAvatar)`
  position: relative;

  inline-size: 100%;
  block-size: auto;

  cursor: pointer;
`;

export const StyledOffsetContainer = styled(OffsetContainer)`
  position: relative;

  inline-size: 30px;
  block-size: 30px;

  &::after {
    border: 1px #000000 solid;
  }

  ${StyledAvatar} {
    inline-size: 30px;
    block-size: 30px;

    & .sa-avatar-widget__image {
      inline-size: 30px;
      block-size: 30px;
    }

    & .sa-avatar-widget-container {
      inset-block-start: 1px;
      inset-inline-start: -1px;
    }

    & .sa-avatar-widget__image {
      margin: 0;
      padding: 0;

      border: 0;
    }

    & .sa-avatar-widget__wrapper {
      background: unset;
    }

    ${theme.breakpoints.up("tablet")} {
      inline-size: 48px;
      block-size: 48px;
      & .sa-avatar-widget__image {
        inline-size: 48px;
        block-size: 48px;
      }

      & .sa-avatar-widget-container {
        inset-block-start: 3px;
        inset-inline-start: -3px;
      }
    }
  }

  ${StyledAnonymousAvatar} {
    inset-block-start: 1px;
    inset-inline-start: -1px;
  }
  ${theme.breakpoints.up("tablet")} {
    inline-size: 48px;
    block-size: 48px;
    &::after {
      border: 2px #000000 solid;
    }

    ${StyledAnonymousAvatar} {
      inset-block-start: 3px;
      inset-inline-start: -3px;
    }
  }
`;
